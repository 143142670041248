import dayjs from "dayjs";
import * as timezone from "dayjs/plugin/timezone";
import * as LocalizedFormat from "dayjs/plugin/localizedFormat";
import * as utc from "dayjs/plugin/utc";

dayjs.extend(LocalizedFormat.default);
dayjs.extend(utc.default);
dayjs.extend(timezone.default);

export default function useDayjs() {
  const coloradoTime = () => dayjs().tz("America/Denver").format("LT");
  return { dayjs, coloradoTime };
}
