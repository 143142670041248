import { defineStore } from "pinia";
import { computed, ComputedRef, Ref, ref } from "vue";
import { IGameOrder } from "@/types/entities/IGameOrder";
import { IAdditionalOrder } from "@/types/entities/IAdditionalOrder";
import { IDepositOrder } from "@/types/entities/IDepositOrder";
import { IOrderTotal } from "@/types/entities/IOrderTotal";
import useDayjs from "@/composables/useDayjs";
import { useServiceStore } from "@/store/service";
import { ICoupon } from "@/types/entities/ICoupon";
import { CouponTypeEnum } from "@/utils/enum/CouponTypeEnum";

export const useOrderStore = defineStore(
  "order",
  () => {
    const { dayjs } = useDayjs();
    const serviceStore = useServiceStore();

    const coupon: Ref<ICoupon | null> = ref(null);
    const couponCartTotal: ComputedRef<boolean> = computed(() => {
      if (!coupon.value) {
        return false;
      }
      return coupon.value.type === CouponTypeEnum.cartTotal;
    });
    const couponGameRecording: ComputedRef<boolean> = computed(() => {
      if (!coupon.value) {
        return false;
      }
      return coupon.value.type === CouponTypeEnum.gameRecording;
    });

    const total: Ref<IOrderTotal[]> = ref([]);
    const addService = (item: IOrderTotal) => {
      total.value.push(item);
    };
    const updateService = (
      item: IGameOrder | IAdditionalOrder | IDepositOrder
    ) => {
      total.value[total.value.findIndex((el) => el.id === item.id)] = item;
    };
    const removeService = (id: number) => {
      total.value = total.value.filter((i) => i.id !== id);
    };

    const gameRecordingIds: ComputedRef<number[]> = computed(() =>
      serviceStore.getGameRecording().map((s) => s.id)
    );
    const invalidDate: ComputedRef<boolean> = computed(() => {
      if (!total.value.length) {
        return true;
      }
      return !!total.value.filter((order) => {
        if (gameRecordingIds.value.includes(order.service_id)) {
          const currentOrder = order as IGameOrder;
          const hours = dayjs(currentOrder.time).format("HH");
          const minutes = dayjs(currentOrder.time).format("mm");
          return (
            dayjs(currentOrder.date)
              .set("hours", +hours)
              .set("minutes", +minutes)
              .diff(dayjs(), "days", true) < 0
          );
        }
        return false;
      }).length;
    });

    return {
      total,
      coupon,
      couponCartTotal,
      couponGameRecording,
      gameRecordingIds,
      invalidDate,
      addService,
      updateService,
      removeService,
    };
  },
  {
    persist: {
      paths: ["total"],
    },
  }
);
