import { defineStore } from "pinia";
import { Ref, ref } from "vue";
import { IService } from "@/types/entities/IService";
import servicesApi from "@/services/api/servicesApi";
import { ApiClientResponse } from "@/types/api/ApiClientResponse";
import { ServiceTypeEnum } from "@/utils/enum/ServiceTypeEnum";
import { ServiceSubTypeEnum } from "@/utils/enum/ServiceSubTypeEnum";

export const useServiceStore = defineStore("service", () => {
  const data: Ref<IService[]> = ref([]);
  const fetching: Ref<boolean> = ref(false);
  function getAll(): Promise<ApiClientResponse<IService[]>> | void {
    if (!data.value.length && !fetching.value) {
      fetching.value = true;
      return servicesApi
        .getAll()
        .then((res) => {
          if (res && res.data && res.data.data) {
            data.value = res.data.data.map((s) => {
              const mainPrice = s.prices.find((p) => p.quantity === 1);
              s.price = mainPrice ? mainPrice.price : 0;
              s.prices = s.prices.filter((p) => p.quantity !== 1);
              return s;
            });
          }
          return res;
        })
        .finally(() => {
          fetching.value = false;
        });
    }
  }

  function getById(id: number): IService | null {
    getAll();
    const service = data.value.find((s) => s.id === id);
    if (service) {
      return service;
    }
    return null;
  }

  function getGameRecording(): IService[] {
    return data.value.filter((s) => s.type === ServiceTypeEnum.gameRecording);
  }
  function getStreaming(): IService[] {
    return data.value.filter(
      (s) =>
        s.type === ServiceTypeEnum.additional &&
        s.subtype === ServiceSubTypeEnum.streaming
    );
  }
  function getDeposit(): IService[] {
    return data.value.filter(
      (s) =>
        s.type === ServiceTypeEnum.additional &&
        s.subtype === ServiceSubTypeEnum.deposit
    );
  }
  function getAdditional(): IService[] {
    return data.value.filter(
      (s) =>
        s.type === ServiceTypeEnum.additional &&
        s.subtype === ServiceSubTypeEnum.additionalTime
    );
  }
  function getScoutGames(): IService[] {
    return data.value.filter(
      (s) =>
        s.type === ServiceTypeEnum.additional &&
        s.subtype === ServiceSubTypeEnum.scoutGame
    );
  }

  return {
    data,
    fetching,
    getAll,
    getById,
    getGameRecording,
    getStreaming,
    getDeposit,
    getAdditional,
    getScoutGames,
  };
});
