import { createApp } from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import "@/scss/styles.scss";
import { createPinia } from "pinia";
import piniaPluginPersistedstate from "pinia-plugin-persistedstate";
import { PiniaSharedState } from "pinia-shared-state";
import VCalendar from "v-calendar";
import VueClickAway from "vue3-click-away";
import { createMetaManager } from "vue-meta";
import "broadcastchannel-polyfill";

const pinia = createPinia();
pinia.use(piniaPluginPersistedstate);
pinia.use(PiniaSharedState({ enable: true, initialize: true, type: "native" }));

const app = createApp(App)
  .use(createMetaManager())
  .use(pinia)
  .use(router)
  .use(VCalendar, {})
  .use(VueClickAway, {});

app.mount("#app");
