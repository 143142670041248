import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-e0e53092"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "footer" }
const _hoisted_2 = { class: "container" }
const _hoisted_3 = { class: "menu" }
const _hoisted_4 = { class: "socials" }
const _hoisted_5 = ["href"]
const _hoisted_6 = { class: "copyright" }
const _hoisted_7 = { class: "app-subtitle" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createVNode(_component_router_link, {
          class: "logo",
          to: { name: $setup.routesName.index }
        }, null, 8, ["to"]),
        _createElementVNode("ul", _hoisted_3, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.menuItems, (item, index) => {
            return (_openBlock(), _createElementBlock("li", {
              class: "menu-item",
              key: index
            }, [
              _createVNode(_component_router_link, {
                class: "menu-link",
                to: { name: item.routeName }
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(item.title), 1)
                ]),
                _: 2
              }, 1032, ["to"])
            ]))
          }), 128))
        ]),
        _createElementVNode("ul", _hoisted_4, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.socialsItems, (item, index) => {
            return (_openBlock(), _createElementBlock("li", {
              class: "socials-item",
              key: index
            }, [
              _createElementVNode("a", {
                class: _normalizeClass(["socials-link", `socials-link_${item.name}`]),
                href: item.url,
                target: "_blank"
              }, null, 10, _hoisted_5)
            ]))
          }), 128))
        ])
      ])
    ]),
    _createElementVNode("div", _hoisted_6, [
      _createElementVNode("div", _hoisted_7, _toDisplayString(`© Copyright ${new Date().getFullYear()} Colorado Sports Video.`), 1)
    ])
  ], 64))
}