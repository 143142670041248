import { createVNode as _createVNode, resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, Transition as _Transition, withCtx as _withCtx, resolveComponent as _resolveComponent, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-00280dd7"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "main-layout" }
const _hoisted_2 = { class: "layout-content" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_view = _resolveComponent("router-view")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode($setup["AppHeader"]),
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_router_view, null, {
        default: _withCtx(({ Component }) => [
          _createVNode(_Transition, {
            name: "fade",
            mode: "out-in"
          }, {
            default: _withCtx(() => [
              (_openBlock(), _createBlock(_resolveDynamicComponent(Component)))
            ]),
            _: 2
          }, 1024)
        ]),
        _: 1
      })
    ]),
    ($setup.showFooter)
      ? (_openBlock(), _createBlock($setup["AppFooter"], { key: 0 }))
      : _createCommentVNode("", true)
  ]))
}