import axiosClient from "./index";
import { IService } from "@/types/entities/IService";
import { ApiClientResponse } from "@/types/api/ApiClientResponse";

export default {
  getAll(): Promise<ApiClientResponse<IService[]>> {
    return axiosClient.get("/services");
  },
  getById(id: number): Promise<ApiClientResponse<IService>> {
    return axiosClient.get(`/services/${id}`);
  },
};
