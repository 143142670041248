const routesName = {
  index: "index",
  services: "services",
  serviceIndex: "serviceIndex",
  serviceOrder: "serviceOrder",
  order: "order",
  checkout: "checkout",
  aboutUs: "aboutUs",
  contactUs: "contactUs",
  termsAndCondition: "termsAndCondition",
  privacyPolicy: "privacyPolicy",
  refundPolicy: "refundPolicy",
  notFound: "notFound",
};

export default routesName;
