import { RouteRecordRaw } from "vue-router";
import routesName from "@/utils/constants/routesName";
import EmptyLayout from "@/layouts/EmptyLayout.vue";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: routesName.index,
    component: () => import("@/views/LandingView.vue"),
    meta: {
      footer: true,
    },
  },
  {
    path: "/services",
    name: routesName.services,
    component: () => import("@/views/ServicesView.vue"),
    meta: {
      footer: true,
    },
  },
  {
    path: "/service/:id",
    name: routesName.serviceIndex,
    component: () => import("@/views/ServiceDetailsView.vue"),
    meta: {
      footer: true,
    },
  },
  {
    path: "/service/:id/order",
    name: routesName.serviceOrder,
    component: () => import("@/views/ServiceOrderView.vue"),
    meta: {
      footer: false,
    },
  },
  {
    path: "/service-request",
    name: "ServiceRequest",
    component: () => import("@/views/ServiceRequestView.vue"),
    meta: {
      footer: true,
    },
  },
  {
    path: "/order",
    name: routesName.order,
    component: () => import("@/views/OrderView.vue"),
    meta: {
      footer: false,
    },
  },
  {
    path: "/checkout",
    name: routesName.checkout,
    component: () => import("@/views/CheckoutView.vue"),
    meta: {
      footer: false,
    },
  },
  {
    path: "/about-us",
    name: routesName.aboutUs,
    component: () => import("@/views/AboutUsView.vue"),
    meta: {
      footer: true,
    },
  },
  {
    path: "/contact-us",
    name: routesName.contactUs,
    component: () => import("@/views/ContactUsView.vue"),
    meta: {
      footer: true,
    },
  },
  {
    path: "/terms-and-condition",
    name: routesName.termsAndCondition,
    component: () => import("@/views/TermsView.vue"),
    meta: {
      footer: true,
    },
  },
  {
    path: "/privacy-policy",
    name: routesName.privacyPolicy,
    component: () => import("@/views/PrivacyPolicyView.vue"),
    meta: {
      footer: true,
    },
  },
  {
    path: "/refund-policy",
    name: routesName.refundPolicy,
    component: () => import("@/views/RefundPolicyView.vue"),
    meta: {
      footer: true,
    },
  },
  {
    path: "/:pathMatch(.*)*",
    name: routesName.notFound,
    meta: {
      layout: EmptyLayout,
      footer: false,
    },
    component: () => import("@/views/NotFoundView.vue"),
  },
];

export default routes;
